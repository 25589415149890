<template>
  <div>
    <Header :title="isEdit ? '修改商品分类' : '新增商品分类'" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
              :wrapperCol="{span: 14, xxl: 13 }">

        <a-form-item name="title" :rules="[{ required: true, message: '必填项不允许为空' }]" label="分类标题">
          <a-input v-model:value="modelRef.title" placeholder="请输入分类标题"></a-input>
        </a-form-item>

        <a-form-item name="sort" extra="优先级越高越靠前，不填则为0" label="优先级">
          <a-input-number :min="0" :precision="0" style="width: 100%;" v-model:value="modelRef.sort" placeholder="请输入优先级"></a-input-number>
        </a-form-item>

        <a-form-item label="是否启用" name="isDisabled" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
        </a-form-item>

        <a-form-item name="description" label="分类描述">
          <a-input v-model:value="modelRef.description" placeholder="请输入分类描述"></a-input>
        </a-form-item>

        <a-form-item label="分类广告图">
          <a-upload
              v-model:file-list="fileList"
              list-type="picture-card"
              action="/admin/ajaxUpload.do"
              accept="image/*"
              @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <Icon icon="PlusOutlined"></Icon>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item v-show="fileList.length" name="redirectUrl" label="外部链接">
          <a-input v-model:value="modelRef.redirectUrl" placeholder="请输入外部链接"></a-input>
        </a-form-item>

        <a-row>
          <a-col :span="24" style="margin-top: 20px;text-align: center;">
            <a-button type="primary" html-type="submit" @click="onSubmit">提交</a-button>
            <a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import Header from '@/components/header/header.vue';
import {getGoodsClassifyDetail, saveGoodsClassify, updateGoodsClassify} from "../../../../service/modules/mall";
export default {
  components: {
    Header,
    Icon
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      fileList: [],
      formState: {},
      modelRef: {},
    }
  },
  created() {
    if(this.isEdit) {
      this.getData();
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getGoodsClassifyDetail({
          id: this.id
        })
        this.loading = false;
        if(ret.code === 200) {
          ret.data.isDisabled = ret.data.isDisabled ? false : true;
          this.fileList = ret.data.imgUrl.split(',').map(item=>{
            return {
              url: item,
            }
          })

          this.modelRef = ret.data;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    handleChange(info) {
      if (info.file.status === 'done') {
        this.fileList = info.fileList;
        const $fileList = [...this.fileList];
        this.fileList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    onSubmit() {
      this.$refs.form.validateFields().then(async () => {
        let postData = JSON.parse(JSON.stringify(this.modelRef));
        postData.isDisabled = postData.isDisabled ? 0 : 1;
        postData.level = 1;
        postData.classifyType = 1;
        const imgList = [];
        this.fileList.forEach(item=>{
          imgList.push(item.url);
        });
        postData.imgUrl = imgList.join(',');
        this.loading = true;
        try {
          let ret;
          if(this.isEdit) {
            postData.id = this.id;
            ret = await updateGoodsClassify(postData);
          } else {
            ret = await saveGoodsClassify(postData);
          }
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.onBack(true);
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
  }
}
</script>

<style scoped>

</style>