<template>
	<div v-if="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item label="分类标题" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入分类标题"/>
					</a-form-item>

          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 190px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['mall_setting_classify_add']" type="primary" @click="onAdd">新增分类</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list">
					<template #bodyCell="{ column, record }">
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
                    <div v-permission="['mall_setting_classify_edit']" @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['mall_setting_classify_open']" v-if="record.isDisabled" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['mall_setting_classify_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
										<div v-permission="['mall_setting_classify_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
  <temp v-else :isEdit="isEdit" :id="id" @back="onBack"></temp>
</template>

<script>
  import temp from './temp.vue'
	import {
		Icon
	} from '@/components/icon/icon.js';
  import {
    deleteGoodsClassify,
    getGoodsClassifyList,
    pullOffShelvesGoodsClassify,
    pullOnShelvesGoodsClassify,
  } from "../../../../service/modules/mall";
	export default {
		components: {Icon, temp },
		data() {
			return {
        id: 0,
				loading: false,
				formState: {},
				organizationList: [],
				cinemaAllList: [],
				fileList: [],
				searchData: {},
				showModal: false,
				list: [],
				columns: [{
					title: '分类标题',
					dataIndex: 'title',
          width: 200
				},
				{
					title: '分类描述',
					dataIndex: 'description'
				},
				{
					title: '优先级',
					dataIndex: 'sort',
          width: 100
				},
        {
          title: '状态',
          key: 'isDisabled',
          width: 100
        },
				{
					title: '操作',
					key: 'action'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				parentData: {},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
      onBack(isRef) {
        this.showModal = false;
        if (isRef) {
          this.$nextTick(() => {
            this.getData();
          })
        }
      },
			async getData() {
				this.loading = true;
				try {
					let ret = await getGoodsClassifyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
            classifyType: 1,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onAdd() {
				this.isEdit = false;
				this.showModal = true;
				this.modelRef = {};
			},
      onEdit(item) {
        this.isEdit = true;
        this.id = item.id;
        this.showModal = true;
      },
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteGoodsClassify({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
      onDisabled(item) {
        this.$confirm({
          title: '提示',
          content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该商品分类吗',
          onOk: async () => {
            this.loading = true;
            try {
              let ret;
              if(item.isDisabled) {
                ret = await pullOnShelvesGoodsClassify({id: item.id});
              } else {
                ret = await pullOffShelvesGoodsClassify({id: item.id});
              }
              this.loading = false;
              if(ret.code === 200) {
                this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
                this.getData();
              }
            } catch(e) {
              this.loading = false;
            }
          }
        })
      },
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
